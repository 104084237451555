<template>
    <div v-bind:class="{ 'py-5': $root.$children[0].isAdminstrationMode }"
        :key="'news' + $root.$children[0].viewComponentKey" style="">
        <b-col cols="12">
            <div>
                <b-row>
                    <b-col cols="12">
                        <h2 id="navigation-title"></h2>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>

                        <hr>


                        <div id="renderer"></div>
                    </b-col>
                </b-row>
            </div>
        </b-col>
    </div>
</template>

<script>
import config from "../config/config.js";
import axios from "axios";
import moment from "moment";
import { Formio } from "formiojs";

export default {
    data: function () {
        return {
            defItem: {},
            userData: {},
            userBaseData: {}

        };
    },
    mounted() {
        let self = this;

        self.loadForm();

    },

    methods: {


        loadForm() {
            let self = this;


            if (self.$route.params.userId !== "new") {
                let userId = self.$root.$children[0].userData.Id;




                if (self.$route.params.userId != undefined) {
                    userId = self.$route.params.userId
                }
                self.userData = JSON.parse(self.$root.$children[0].userData.UserDataJson)
                self.userBaseData = self.$root.$children[0].userData;
                axios
                    .get(config.API_URL + "/api/userDataById/" + userId, {})
                    .then((response) => {
                        if (response.status == 200) {
                            if (response.data != null) {
                                self.userBaseData = response.data;

                                if (response.data.userData.UserDataJson != null) {
                                    self.userData = JSON.parse(response.data.userData.UserDataJson);
                                } else {
                                    self.userData = {}
                                }
                            }

                            if (self.userData == null) {
                                self.userData = {}
                            }




                            window.formId = 429;
                            axios
                                .get(config.API_URL + "/api/form/429")
                                .then((response) => {
                                    if (
                                        typeof JSON.parse(response.data.value).formDefintion ===
                                        "string"
                                    ) {
                                        self.defItem = JSON.parse(
                                            JSON.parse(response.data.value).formDefintion
                                        );
                                    } else {
                                        self.defItem = JSON.parse(response.data.value).formDefintion;
                                    }
                                    self.showForm = true;
                                    Formio.setBaseUrl(config.API_URL + "/api/formManager");
                                    Formio.setProjectUrl(config.API_URL + "/api/formManager");


                                    self.currentForm = Formio.createForm(
                                        document.getElementById("renderer"),
                                        self.defItem,
                                        {

                                            language: "de",
                                            i18n: {
                                                de: {
                                                    error: "Bitte Fehler korrigieren",

                                                    next: "Weiter",

                                                    previous: "Zurück",
                                                    cancel: "Abbrechen",

                                                    required: "{{field}} ist ein Pflichtfeld",
                                                    submit: "Abschicken",
                                                },
                                            },
                                        }
                                    )
                                        .then(function (form) {


                                            self.userData.vorname = self.userBaseData.firstName
                                            self.userData.nachname = self.userBaseData.lastName

                                            self.userData.gruppe = (self.userData.gruppe) ? self.userData.gruppe : self.userBaseData.role;
                                            self.userData.benutzer_eindeutigeBenutzerId = userId
                                            self.userData.benutzer_gemeindeBehorde = ( self.userData.benutzer_gemeindeBehorde) ?  self.userData.benutzer_gemeindeBehorde: self.userBaseData.communityData.communityName 
                                            self.userData.benutzer_dienstlicheEMailAdresse = (self.userBaseData.emailAddress) ? self.userBaseData.emailAddress.trim() : self.$root.$children[0].userData.emailAddress.trim();

                                            form.submission = {
                                                data: self.userData,
                                            };

                                            self.viewListComponentKey = moment().format("x");
                                            window.form = form;
                                            form.nosubmit = false;

                                            form.on("nextPage", function (submission) {
                                                form.saveDraft();
                                                window.formDefinition = form.schema;
                                                window.draftSubmission = submission;

                                            });
                                            form.nosubmit = true;
                                            form.on("submit", function (submission) {
                                                let formValue = {};

                                                formValue.value = JSON.stringify(submission);

                                                axios
                                                    .put(config.API_URL + "/api/signup", formValue)
                                                    .then((response) => {
                                                        if (response != undefined) {
                                                            self.$root.$children[0].showMessage(
                                                                "Gespeichert",
                                                                "Daten gespeichert",
                                                                "success"
                                                            );
                                                            self.loadForm();

                                                        } else {
                                                            self.$root.$children[0].showMessage(
                                                                "Fehler",
                                                                "Es ist Fehler aufgetreten",
                                                                "danger"
                                                            );
                                                        }
                                                    })
                                                    .catch((e) => {
                                                        console.log(e);
                                                    });
                                            });


                                        });
                                });
                        }
                    });
            }
            else {
                window.formId = 429;
                axios
                    .get(config.API_URL + "/api/collectionItem/429")
                    .then((response) => {
                        if (
                            typeof JSON.parse(response.data.value).formDefintion ===
                            "string"
                        ) {
                            self.defItem = JSON.parse(
                                JSON.parse(response.data.value).formDefintion
                            );
                        } else {
                            self.defItem = JSON.parse(response.data.value).formDefintion;
                        }
                        self.showForm = true;
                        Formio.setBaseUrl(config.API_URL + "/api/formManager");
                        Formio.setProjectUrl(config.API_URL + "/api/formManager");


                        self.currentForm = Formio.createForm(
                            document.getElementById("renderer"),
                            self.defItem,
                            {

                                language: "de",
                                i18n: {
                                    de: {
                                        error: "Bitte Fehler korrigieren",

                                        next: "Weiter",

                                        previous: "Zurück",
                                        cancel: "Abbrechen",

                                        required: "{{field}} ist ein Pflichtfeld",
                                        submit: "Abschicken",
                                    },
                                },
                            }
                        )
                            .then(function (form) {

                                self.userData = {}
                                self.userBaseData = self.$root.$children[0].userData;
                                self.userData.benutzer_gemeindeBehorde = (self.userBaseData.Community) ? self.userBaseData.Community : self.userData.benutzer_gemeindeBehorde

                                form.submission = {
                                    data: self.userData,
                                };



                                self.viewListComponentKey = moment().format("x");
                                window.form = form;
                                form.nosubmit = false;

                                form.on("nextPage", function (submission) {
                                    form.saveDraft();
                                    window.formDefinition = form.schema;
                                    window.draftSubmission = submission;

                                });
                                form.nosubmit = true;
                                form.on("submit", function (submission) {
                                    let formValue = {};
                                    submission.data.CommunityId = self.userBaseData.communityId;

                                    formValue.value = JSON.stringify(submission);

                                    axios
                                        .post(config.API_URL + "/api/addUserToCommunity", formValue)
                                        .then((response) => {
                                            if (response != undefined) {
                                                self.$root.$children[0].showMessage(
                                                    "Gespeichert",
                                                    "Daten gespeichert",
                                                    "success"
                                                );
                                                self.loadForm();

                                            } else {
                                                self.$root.$children[0].showMessage(
                                                    "Fehler",
                                                    "Es ist Fehler aufgetreten",
                                                    "danger"
                                                );
                                            }
                                        })
                                        .catch((e) => {
                                            console.log(e);
                                        });
                                });


                            });
                    });
            }
        }

    }
};
</script>